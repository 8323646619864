.icons-container img {
    width: calc(100px + 2vmin);
    height: auto;
    box-shadow: 0 0 10px #3790e2;
}

.icons-container a{
    text-decoration: none;
    color: #3790e2;
}

.icons-container {
    font-size: calc(30px + 2vmin);
    text-shadow: 0 0 10px #3790e2;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -20px;
}

.icons-container p {
    text-shadow: 0 0 10px #3790e2;
}

[glow] .glowable-element {
    color: var(--glow-color);
}