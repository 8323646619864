:root {
  --menu-container-width: 500px;
  --menu-container-height: 700px;
}

@font-face {
	font-family: 'Roboto-Regular';
	src: url(/src/assets/font/Roboto-Regular.ttf);
}

.emoji {
  width: calc(1px + 2vmin);
  height: auto;
  filter: drop-shadow(0px 0px 10px #fffc4c);
  margin: 1px;
}

.lastcommit {
  color: #bbaa2a;
  text-shadow: 0 0 10px #fffc4c;
  font-size: calc(1px + 2vmin);
  display: flex;
}

.menu-position-relative{
  position: relative;
  height: 100vh;
}

.menu-container {
  text-align: center;
  margin-top: calc(50px + 2vmin);
}

.menu-container a {
  text-decoration: none;
  color: #0d3358;
}

.menu-text {
  font-size: calc(30px + 2vmin);
  text-shadow: 0 0 10px #3790e2;
}

body {
  background-color: #5b5b5b;
}

body a{
  text-shadow: 0 0 10px rgb(7, 9, 79);
}

.App {
  text-align: center;
  font-family: 'Roboto-Regular';
}

.App-logo {
  display: flex;
  height: 40vmin;
  width: auto;
}

.App-header { 
  text-shadow: 0 0 10px black;
  box-shadow: 0 0 10px black;
  background-color: #515151;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header p {
  margin-left: calc(5px + 2vmin);
}

.App-header img {
  width: calc(50px + 2vmin);
  height: auto;
}

.App-header a {
  color: white;
  text-decoration: none;
}

[glow] .glowable-text {
  color: var(--glow-color);
}

[glow] .glowable-element {
  color: var(--glow-color);
}