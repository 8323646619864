.Register {
    background-color: rgb(16, 16, 16);
}

.main-title p {
    font-size: calc(50px + 2vmin);
    color: white;
    position: absolute;
}

.main-rectangle-fix-position {
    position: relative;
    display: inline-block;
    height: 100vh;
}

.main-rectangle #rectangle {
    border-radius: 10px;
    filter: blur(3px);
    width: calc(500px + 2vmin);
    height: calc(700px + 2vmin);
    left: calc(50% - calc(500px + 2vmin) / 2);
    top: calc(50% - calc(700px + 2vmin) / 2);
    outline: 1px solid purple;
    box-shadow: 0 0 10px purple;
    background-color: black;
    position: absolute;
}

.main-rectangle #title {
    color: rgb(178, 178, 178);
    text-shadow: 0 0 10px rgb(212, 0, 212);
    font-size: calc(50px + 2vmin);
    top: calc(50px - 2vmin);
    margin-bottom: -1px;
    position: relative;
}

.main-rectangle #email {
    color: rgb(178, 178, 178);
    text-shadow: 0 0 10px rgb(212, 0, 212);
    font-size: calc(10px + 2vmin);
    top: calc(50px - 2vmin);
    position: relative;
}

.main-rectangle #password {
    color: rgb(178, 178, 178);
    text-shadow: 0 0 10px rgb(212, 0, 212);
    font-size: calc(10px + 2vmin);
    top: calc(50px - 2vmin);
    position: relative;
}

.main-rectangle input {
    color: rgb(255, 255, 255);
    background-color: purple;
    filter: drop-shadow(0 0 10px rgb(212, 0, 212));
    width: calc(350px + 2vmin);
    height: calc(50px + 2vmin);
    border-radius: 10px;
    margin-top: calc(25px + 2vmin);
    border: none;
    font-size: calc(10px + 2vmin);
    text-shadow: 0 0 10px rgb(212, 0, 212);
}

.main-rectangle #custom-button {
    border-radius: 10px;
    border: none;
    width: calc(450px + 2vmin);
    height: calc(50px + 2vmin);
    color: rgb(255, 255, 255);
    background-color: purple;
    filter: drop-shadow(0 0 10px rgb(212, 0, 212));
    margin: calc(150px + 2vmin);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(10px + 2vmin);
    text-shadow: 0 0 10px rgb(212, 0, 212);
}

[glow] .glowable-element {
    color: var(--glow-color)
}