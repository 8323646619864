.AppsHome a{
    text-decoration: none;
    color: white;
}

.AppsHome {
    text-align: center;
    color: white;
}

.AppsHome button {
    width: calc(250px + 2vmin);
    height: auto;
}

.Discord {
    font-size: calc(20px + 2vmin);
    color: rgb(0, 76, 255);
    text-shadow: rgb(0, 76, 255);
    margin: calc(20px + 2vmin);
}

.Discord button{
    border: none;
    border-radius: 10px;
    font-size: calc(20px + 2vmin);
    color: rgb(0, 76, 255);
    text-shadow: 0 0 10px rgb(0, 76, 255);
    filter: drop-shadow(0 0 10px rgb(0, 76, 255));
    background-color: rgb(0, 0, 63);
}

.Tools {
    font-size: calc(20px + 2vmin);
    color: rgb(57, 57, 57);
    text-shadow: rgb(57, 57, 57);
    margin: calc(20px + 2vmin);
}

.Tools button{
    border: none;
    border-radius: 10px;
    font-size: calc(20px + 2vmin);
    color: rgb(100, 100, 100);
    text-shadow: 0 0 10px rgb(100, 100, 100);
    filter: drop-shadow(0 0 10px rgb(57, 57, 57));
    background-color: rgb(74, 74, 74);
}

[glow] .glowable-element {
    color: var(--glow-color);
}