.Temu {
    background-color: #201406
}

.Temu a{
    text-decoration: none;
}

.temu-roulette-1 {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: -5px;
    left: calc(50% - 170px);
}

.chinese {
    justify-content: center;
    align-items: center;
    text-align: center;
}