.Base64 {
    font-size: calc(20px + 2vmin);
}

.main-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#Text {
    margin: 30px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    outline: none;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

#Text-converted {
    margin: 30px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    outline: none;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

#Type-select {
    margin: 30px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    outline: none;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

.Convert-button {
    margin: 50px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

.arrow {
    margin: -4vmin;
    width: 200px;
    height: auto;
    filter: drop-shadow(0 0 10px rgb(58, 58, 58));
}