.Webhook {
    font-size: calc(20px + 2vmin);
}

.Webhook a {
    color: white;
}

.rand-checkbox input[type="checkbox"] {
    background-color: rgb(62, 57, 67);
    width: calc(20px + 2vmin);
    height: calc(20px + 2vmin);
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    border-radius: 10px;
}

.rand-checkbox label {
    color: white;
    align-items: center;
    margin: 10px;
    text-shadow: 0 0 10px rgb(0, 0, 0);
}

.main-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#Url {
    margin: 30px;
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: calc(150px + 2vmin);
    line-height: 2.0;
    outline: none;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

#Msg {
    margin: 30px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    outline: none;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

#Count {
    margin-bottom: 10px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    width: calc(550px + 2vmin);
    height: auto;
    outline: none;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    text-align: center;
}

#Delay {
    margin: 30px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    outline: none;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

#Send-button {
    margin: 50px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}

#Download-button {
    margin-bottom: 40px;
    font-size: calc(10px + 2vmin);
    border: none;
    color: rgb(255, 255, 255);
    background-color: rgb(62, 57, 67);
    width: calc(550px + 2vmin);
    height: auto;
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    text-shadow: 0 0 10px rgb(0, 0, 0);
    border-radius: 10px;
    text-align: center;
}