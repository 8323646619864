.HowToUse {
    background-color: rgb(63, 63, 63);
}

.code-area {
    background-color: rgb(50, 50, 50);
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    border-radius: 10px;
    font-size: calc(5px + 2vmin);
    width: calc(350px + 2vmin);
    height: auto;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: 0 0 10px rgb(0, 0, 0);
    text-align: center;
}

.image {
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
    border-radius: 10px;
    margin-top: 20px;
}

.container {
    background-color: rgb(46, 46, 46);
    font-size: calc(20px + 2vmin);
    color: white;
    text-shadow: 0 0 10px rgb(0, 0, 0);
    filter: drop-shadow(0 0 10px rgb(15, 14, 16));
}

.title {
    text-align: left;
    color: white;
    text-shadow: 0 0 10px rgb(0, 0, 0);
    font-size: calc(30px + 2vmin);
}